import React, {useEffect} from 'react';
import './Home.css';
import Carousel from '../Carousel/Carousel';
import Heading from '../Heading/Heading';
import ColumnLogo from '../ColumnLogo/ColumnLogo';
import Cards from '../Cards/Cards';
import About from '../About/About';
function Home() {
  useEffect(() => {
    document.title = `Esse's Cakes | 🎂🧁🍰`;
  });

  return (
    <div className="Home">
      <Carousel />
      <Heading />
      <ColumnLogo />
      <About />
      <Cards />
    </div>
  );
}

export default Home;
