import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="Footer">
      <div className="row text-center no-margin">
        <div className="col-12">
          <br />
          <hr className="light" />
          <h5>Connect</h5>
          <hr className="light" />
        </div>
        <div className="col-12 social">
          <a
            href="https://www.facebook.com/EssesCakes"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/essescakes"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-12">
          <hr className="light-100" />
          <h5>&copy; 2023 essescakes.co.uk</h5>
        </div>
      </div>
    </div>
  );
}

export default Footer;
