import React from 'react';
import './ColumnLogo.css';

function ColumnLogo() {
  return (
    <div className="container-fluid padding-btm">
      <div className="row text-center padding-btm">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <i className="fas fa-gem"></i>
          <h3>Quality</h3>
          <p>Our cakes are high quality</p>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <i className="fas fa-paint-brush"></i>
          <h3>Innovative</h3>
          <p>Unique cake designs</p>
        </div>
        <div className="col-sm-12 col-md-4">
          <i className="fas fa-birthday-cake"></i>
          <h3>Individualised</h3>
          <p>Styled specially for your occasion</p>
        </div>
      </div>
      <hr className="my-4" />
    </div>
  );
}

export default ColumnLogo;
