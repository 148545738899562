export const CAKE_TYPE = {
    CUPCAKE: "Cupcake",
    CAKE: "Single Layer Cake",
    TWO_TIER_CAKE: "Two Tier Cake",
    NOVELTY: "Novelty / Themed Cake"
}

export const CAKE_SIZE = {
    10: "10 inches",
    8: "8 inches",
    6: "6 inches"
}

export const CAKE_SHAPE = {
    ROUND: "Round",
    SQAURE: "Sqaure"
}

export const CAKE_LAYERS = {
    1: "1",
    2: "2"
}

export const CAKE_FLAVOUR = {
    VANILLA: "Vanilla",
    CHOCOLATE: "Chocolate",
    LEMON: "Lemon",
    RED_VELVET: "Red velvet",
    CARROT: "Carrot"
}

export const CAKE_OPTIONAL = {
    YES: "Yes",
    NO: "No"
}

export const CAKE_FILLING_FROSTING = {
    FONDANT: "Fondant",
    VANILLA_BUTTERCREAM: "Vanilla Buttercream",
    LEMON_BUTTERCREAM: "Lemon Buttercream",
    CHOCOLATE_BUTTERCREAM: "Chocolate Buttercream",
    CREAM_CHEESE: "Cream cheese",
}