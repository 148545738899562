import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from 'react-router-dom';
import Home from './components/Home/Home';
import Order from './components/Order/Order';
import Contact from './components/Contact/Contact';
import Faq from './components/Faq/Faq';
import Footer from './components/Footer/Footer';
import './App.css';
import logo from './cakelogo-30.png';
import FormSent from './components/FormSent/FormSent';


function App() {
  return (
    <Router>
      <div className="App">
        <div className="content-wrap">
          <nav
            id="navbar-scroll"
            className="navbar navbar-expand-md navbar-light bg-light sticky-top"
          >
            <div className="container-fluid navbar-content">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="logo" />
                <span>Esse&apos;s Cakes</span>
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="nav-link nav-active"
                      exact
                      to="/"
                    >
                    Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="nav-link nav-active"
                      to="/order"
                    >
                    Order
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="nav-link nav-active"
                      to="/contact"
                    >
                    Contact
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="nav-link nav-active"
                      to="/faq"
                    >
                    FAQs
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="content">
            <Switch>
              <Route path="/sent/:message">
                <FormSent />
              </Route>
              <Route path="/faq">
                <Faq />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/order">
                <Order />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
