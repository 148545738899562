import React, {useEffect} from 'react';
import './Faq.css';

function Faq() {
  useEffect(() => {
    document.title = `Esse's Cakes | FAQs 👩🏾‍🏫`;
  });

  return (
    <div className="Faq">
      <h1 className="display-4 text-center">FAQ</h1>
      <p className="lead text-center">Some frequently asked questions</p>
      <div className="faq-container">
        <p>
          <b>How do I place an order?</b>
        </p>
        <p>Use the form on the order page to place your order.</p>
        <p>
          <b>How do I pay?</b>
        </p>
        <p>
        You can pay by debit card or BACS transfer. Once you order has been
        confirm, you will receive an email with payment details together with an
        invoice.
        </p>
        <p>
          <b>Can I change my order?</b>
        </p>
        <p>
        You can amend the details of your order within 48 hours of placing the
        order
        </p>
        <p>
          <b>Do you refund after the cake is ordered?</b>
        </p>
        <p>
        No refund is given if after paying the deposit you change your mind that
        you no longer require the cake as deposit are non-refundable or non-
        transferable. No refund is given if after paying in full you cancel less
        than two weeks before the event. This is because the in most cases the
        items for the cake would have already been purchased. 50% refund will be
        given if after paying the full amount you cancelled two weeks before
        your event. Full refund will be given if there was an issue with your
        cake from our ends e.g. the cake was majorly damaged on transit beyond
        repair.
        </p>
        <p>
          <b>Deposit?</b>
        </p>
        <p>
        A deposit of 50% is required to secure your booking and the remaining
        50% is due 1 week before the event.
        </p>
        <p>Dessert cakes requires upfront payment on the day of order</p>
        <p>
          <b>How should I store the cake?</b>
        </p>
        <p>
        Celebration cakes should be stored in a cool dry place and not in the
        refrigerator
        </p>
        <p>
        Cakes with cream such as cream cheese frosting should be stored in the
        refrigerator
        </p>
        <p>
          <b>How do I transport my cake?</b>
        </p>
        <p>
        If you are collect your cake, it is best to transport on the boot of the
        car standing on a non-slippery surface. Cake with cheese frosting should
        be transported in a cool car. You can achieve this by putting on the air
        condition in the car.
        </p>
      </div>
    </div>
  );
}

export default Faq;
