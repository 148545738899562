import React from 'react';
import PropTypes from 'prop-types';

function Card(props) {
  return (<div className="card">
    <img src={props.image} className="card-img-top" alt="..."/>
    <div className="card-body">
      <h5 className="card-title">{props.title}</h5>
      <p className="card-text">{props.text}</p>
    </div>
  </div>);
}

Card.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
}

export default Card;
