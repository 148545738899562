import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import './Contact.css';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    document.title = `Esse's Cakes | Contact me 📞`;
  });

  function encode(data) {
    return Object.keys(data)
        .map(
            (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
        )
        .join('&');
  }

  function handleSubmit(event) {
    setError(false);
    axios
        .post(
            '/',
            encode({
              'form-name': 'contact',
              'no-contact-field': '',
              name,
              email,
              message,
            }),
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}},
        )
        .then(() => history.push(`/sent/${name} thanks for your message! We will contact you shortly`))
        .catch(() => setError(true));
    event.preventDefault();
  }
  return (
    <div className="Contact">
      {error && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>Something went wrong!</strong> Please try again
          <button
            type="button"
            className="close"
            onClick={() => setError(false)}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <h1 className="display-4 text-center">Contact</h1>
      <p className="lead text-center">
        Please use the form below to send an enquiry and we will get back to
        you as soon as possible
      </p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
                required
              />
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                id="contact-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                required
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="contact-message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                required
              />
            </div>
          </div>
        </div>
        <button type="submit" className={`btn ${error ? 'btn-danger': 'btn-primary'} order-button-right`}>
          Send message
        </button>
      </form>
    </div>
  );
}

export default Contact;
