import React from 'react';
import './About.css';

function About() {
  return (
    <div className="About">
      <h1 className="display-4">About</h1>
      <p className="lead">Make birthdays or special occasions unforgettable,
        by choosing to design your cakes with Esse&apos;s cakes.
        From carrot cakes to rainbow candy-themed cakes,
        I make personalised cakes and cupcakes designed specially to your
        requirements.</p>
    </div>
  );
}

export default About;
