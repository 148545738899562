import React from 'react';
import './SubmitButton.css';
import PropTypes from 'prop-types';

function SubmitButton(props) {
  return (
    <button type="submit" className={`btn ${props.error ? 'btn-danger' : 'btn-primary'} order-button-right`}>
      Submit Order
    </button>
  );
}

SubmitButton.propTypes = {
  error: PropTypes.bool
}

export default SubmitButton;
