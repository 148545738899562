import React, { useState, useEffect } from 'react';
import axios from 'axios';

// components
import UserDetails from '../UserDetails/UserDetails';
import SubmitButton from './../SubmitButton/SubmitButton';
import { useHistory } from 'react-router-dom';
import CakeDetails from '../CakeDetails/CakeDetails';

// constants
import { CAKE_FILLING_FROSTING, CAKE_FLAVOUR, CAKE_LAYERS, CAKE_TYPE } from '../../constants/cakeDetails';

// styles
import './Order.css';
import Quote from '../Quote/Quote';

function Order() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [eventType, setEventType] = useState('');
  const [cakeType, setCakeType] = useState(CAKE_TYPE.NOVELTY);
  const [numberOfBoxes, setNumberOfBoxes] = useState('');
  const [cakeSize, setCakeSize] = useState('');
  const [topCakeLayerSize, setTopCakeLayerSize] = useState('');
  const [cakeShape, setCakeShape] = useState('');
  const [cakeLayers, setCakeLayers] = useState(CAKE_LAYERS[1]);
  const [cakeFlavour, setCakeFlavour] = useState(CAKE_FLAVOUR.VANILLA);
  const [jamFilling, setJamFilling] = useState('');
  const [lemonCurdFilling, setLemonCurdFilling] = useState('');
  const [cakeFillingOrFrosting, setCakeFillingOrFrosting] = useState(CAKE_FILLING_FROSTING.FONDANT);
  const [details, setDetails] = useState('');
  const [cakeImage, setCakeImage] = useState('');
  const [quote, setQuote] = useState(0);
  const [error, setError] = useState(false);

  const history = useHistory();

  useEffect(() => {
    document.title = `Esse's Cakes | Place an Order 🧁`;
  });

  function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  }

  function handleSubmit(event) {
    setError(false);
    axios
      .post(
        '/',
        encode({
          'form-name': 'order',
          'no-field': '',
          'cake-type': cakeType,
          'number-of-boxes': numberOfBoxes,
          'bottom-layer-cake-size': cakeSize,
          'top-layer-cake-size': topCakeLayerSize,
          'cake-layers': cakeLayers,
          'cake-shape': cakeShape,
          flavour: cakeFlavour,
          filling: cakeFillingOrFrosting,
          'jam-filling': jamFilling,
          'lemon-curd-filling': lemonCurdFilling,
          details,
          'cake-image': cakeImage,
          'delivery-or-collection-time': new Date(dueDate).toString(),
          'event-type': eventType,
          name,
          email,
          phone,
          quote: `£${quote}`
        }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
      )
      .then(() => {
        history.push(`/sent/${name} we have your order! We will contact you shortly`)
      })
      .catch(() => setError(true));
    event.preventDefault();
  }

  return (
    <div className="Order">
      {error && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>Something went wrong!</strong> Please try again
          <button
            type="button"
            className="close"
            onClick={() => setError(false)}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <h1 className="display-4 text-center">Order</h1>
      <p className="lead text-center">
        Get a quote and order, then we will contact you as soon as possible
      </p>
      <form onSubmit={handleSubmit} className="order-form">
        <div className="form-main">
          <CakeDetails
            dueDate={dueDate}
            eventType={eventType}
            cakeFlavour={cakeFlavour}
            cakeFillingOrFrosting={cakeFillingOrFrosting}
            details={details}
            cakeImage={cakeImage}
            cakeType={cakeType}
            numberOfBoxes={numberOfBoxes}
            cakeSize={cakeSize}
            topCakeLayerSize={topCakeLayerSize}
            cakeShape={cakeShape}
            cakeLayers={cakeLayers}
            jamFilling={jamFilling}
            lemonCurdFilling={lemonCurdFilling}
            setDueDate={setDueDate}
            setEventType={setEventType}
            setCakeFlavour={setCakeFlavour}
            setCakeFillingOrFrosting={setCakeFillingOrFrosting}
            setDetails={setDetails}
            setCakeImage={setCakeImage}
            setCakeType={setCakeType}
            setNumberOfBoxes={setNumberOfBoxes}
            setCakeSize={setCakeSize}
            setTopCakeLayerSize={setTopCakeLayerSize}
            setCakeShape={setCakeShape}
            setCakeLayers={setCakeLayers}
            setJamFilling={setJamFilling}
            setLemonCurdFilling={setLemonCurdFilling}
          />
        </div>
        <div className="form-aside">
          <hr />
          <UserDetails
            name={name}
            email={email}
            phone={phone}
            setName={setName}
            setEmail={setEmail}
            setPhone={setPhone}
          />
          <div>
            <small><span className="star">*</span> Indicates a required field</small>
          </div>
          <div className="form-row form-footer">
            <div className="col-sm d-flex justify-content-end">
              <Quote
                cakeType={cakeType}
                numberOfBoxes={numberOfBoxes}
                cakeSize={cakeSize}
                cakeFillingOrFrosting={cakeFillingOrFrosting}
                quote={quote}
                setQuote={setQuote}
              />
            </div>
          </div>
          <div className="form-row form-footer">
            <div className="col-sm  d-flex button-container justify-content-end">
              <SubmitButton error={error} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Order;
