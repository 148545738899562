export default function(...classes) {
    const classList = []
    classes.forEach((className) => {
        if (typeof className === "string") {
            classList.push(className)
        } else if (typeof className === "object") {
            Object.keys(className).forEach((key) => {
                if (className[key]) {
                    classList.push(key)
                }
            })
        }
    })
    return classList.join(" ")
}