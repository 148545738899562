import React from 'react';
import {useParams} from 'react-router-dom';

function FormSent() {
  const {message} = useParams();
  return (
    <div>
      <h1 className="display-4 text-center">Sent!</h1>
      <p className="lead text-center">{message}</p>
    </div>
  );
}

export default FormSent;
