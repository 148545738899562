import React from 'react';
import './Heading.css';

function Heading() {
  return (
    <div className="container-fluid Heading">
      <div className="row welcome text-center">
        <div className="col-12">
          <br />
          <h1 id="about-section" className="display-4">
            The essence of delicious cakes
          </h1>
        </div>
        <hr />
        <div className="col-12">
          <p className="lead">Freshly baked with quality ingredients</p>
        </div>
      </div>
    </div>
  );
}

export default Heading;
