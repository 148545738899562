import React from 'react';
import PropTypes from 'prop-types';

function UserDetails(props) {
  return (
    <React.Fragment>
      <div className="form-row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="name">Name <span className="star">*</span></label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter name"
              value={props.name}
              onChange={(e) => props.setName(e.target.value)}
              name="name"
              required
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="email">Email address <span className="star">*</span></label>
            <input
              type="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              value={props.email}
              onChange={(e) => props.setEmail(e.target.value)}
              name="email"
              required
            />
          </div>
        </div>
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone number</label>
            <input
              type="text"
              className="form-control"
              id="phoneNumber"
              placeholder="Enter phone number"
              value={props.phone}
              onChange={(e) => props.setPhone(e.target.value)}
              name="phone"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

UserDetails.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  phone: PropTypes.string,
  setPhone: PropTypes.func.isRequired
}

export default UserDetails;
