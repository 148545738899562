import React from 'react';
import Card from '../Card/Card';
import cake7 from './cake7_900h_900w.jpg';
import cake18 from './cake18_900h_900w.jpg';
import cakepool from './cakepool_900h_900w.jpg';

const cakes = [
  {
    image: cake18,
    title: '18th Birthday Cake',
    text: 'Number 18 celebration cake for Cairo\'s 18th birthday. Colour scheme blue, black and white. Flavour: Vanilla with buttercream and strawberry jam.',
  },
  {
    image: cake7,
    title: 'Rainbow Birthday Cake',
    text: 'Dribble cake with rainbow layer cake inside for Zara\'s 7th Birthday',
  },
  {
    image: cakepool,
    title: 'Sporty Birthday Cake',
    text: 'Swimming pool theme cake for a Layo\'s 10th birthday.',
  },
];

function Cards() {
  return (
    <div className="container-fluid">
      <div className="card-deck">
        {cakes.map((cake, index)=> <Card
          key={index}
          image={cake.image}
          title={cake.title}
          text={cake.text}
        />)}
      </div>
    </div>
  );
}

export default Cards;
