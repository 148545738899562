import React from 'react';
import collage from './bg-col.png';
import logo from './bg-logo.png';
import pink from './bg-pink.png';
import {Link} from 'react-router-dom';
import './Carousel.css';

function Carousel() {
  return (
    <React.Fragment>
      <div
        className="carousel-small"
        style={{backgroundImage: `url(${logo})`}}
      ></div>
      <div id="slides" className="carousel slide" data-ride="carousel">
        <ul className="carousel-indicators">
          <li data-target="#slides" data-slide-to="0" className="active"></li>
          <li data-target="#slides" data-slide-to="1"></li>
          <li data-target="#slides" data-slide-to="2"></li>
        </ul>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={logo} alt="logo" />
          </div>
          <div className="carousel-item">
            <img src={collage} alt="bg-col" />
            <div className="carousel-caption">
              <h3>
                We will work together to bring your requirements to a reality
              </h3>
              <Link
                role="button"
                className="btn btn-primary btn-lg"
                to="/order"
              >
                Order
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img src={pink} alt="bg-pink" />
            <div className="carousel-caption">
              <h1 className="display-2">Esse&apos;s cakes</h1>
              <h3>The essence of delicious cakes</h3>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Carousel;
