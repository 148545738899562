import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// constants
import { CAKE_FILLING_FROSTING, CAKE_SIZE, CAKE_TYPE } from '../../constants/cakeDetails';

// styles
import "./Quote.css";

function Quote({
    cakeType,
    numberOfBoxes,
    cakeSize,
    cakeFillingOrFrosting,
    quote,
    setQuote
}) {

    useEffect(() => {
        let cost = 0;
        if (cakeType === CAKE_TYPE.NOVELTY) {
            cost = 50;
        } else if (cakeType === CAKE_TYPE.TWO_TIER_CAKE) {
            cost = 90;
        } else if (cakeType === CAKE_TYPE.CUPCAKE) {
            cost = numberOfBoxes * 24;
        } else if (cakeFillingOrFrosting === CAKE_FILLING_FROSTING.FONDANT && cakeSize === CAKE_SIZE[6]) {
            cost = 35;
        } else if (cakeFillingOrFrosting === CAKE_FILLING_FROSTING.FONDANT && cakeSize === CAKE_SIZE[8]) {
            cost = 45;
        } else if (cakeFillingOrFrosting === CAKE_FILLING_FROSTING.FONDANT && cakeSize === CAKE_SIZE[10]) {
            cost = 60;
        } else if (cakeFillingOrFrosting !== CAKE_FILLING_FROSTING.FONDANT && cakeSize === CAKE_SIZE[6]) {
            cost = 30;
        } else if (cakeFillingOrFrosting !== CAKE_FILLING_FROSTING.FONDANT && cakeSize === CAKE_SIZE[8]) {
            cost = 40;
        } else if (cakeFillingOrFrosting !== CAKE_FILLING_FROSTING.FONDANT && cakeSize === CAKE_SIZE[10]) {
            cost = 55;
        }
        setQuote(cost)
    }, [cakeType,
        numberOfBoxes,
        cakeSize,
        cakeFillingOrFrosting,])


    return <div className="qoute">
        <small>Your quote</small>
        <p>from £{quote}</p>
    </div>
}

Quote.propTypes = {
    cakeType: PropTypes.string,
    numberOfBoxes: PropTypes.string,
    cakeSize: PropTypes.string,
    cakeFillingOrFrosting: PropTypes.string,
    quote: PropTypes.number,
    setQuote: PropTypes.func.isRequired
}

export default Quote;