import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// utils
import getCurrentDatetimeLocal from '../../utils/getCurrentDatetimeLocal';
import createClass from '../../utils/createClass';

// constants 
import { CAKE_TYPE, CAKE_SIZE, CAKE_SHAPE, CAKE_LAYERS, CAKE_FLAVOUR, CAKE_FILLING_FROSTING, CAKE_OPTIONAL } from '../../constants/cakeDetails';

// styles
import { ReactComponent as TwoTierCakeIcon } from './cake.svg';
import { ReactComponent as CakeIcon } from './singlecake.svg';
import { ReactComponent as CupcakeIcon } from './cupcake.svg';
import NoveltyCakeIcon from './unicorncake.png'
import "./CakeDetails.css"

function CakeDetails(props) {
  const [preview, setPreview] = useState()

  const fileInput = useRef(null)

  useEffect(() => {
    if (!props.cakeImage) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(props.cakeImage)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [props.cakeImage])

  return (
    <React.Fragment>
      <div className="form-row">
        <div className="col-sm">
          <p>Select Cake Type <small className="star">*</small></p>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm">
          <div className="form-group">
            <div className="icon-radio-wrapper">
              <label
                className={createClass("icon-label", { "icon-label-selected": props.cakeType === CAKE_TYPE.NOVELTY })}
                htmlFor="novelty-cake"
              >
                <input
                  className="icon-radio"
                  type="radio"
                  id="novelty-cake"
                  name="cake-type"
                  value={CAKE_TYPE.NOVELTY}
                  checked={props.cakeType === CAKE_TYPE.NOVELTY}
                  onChange={(e) => {
                    props.setNumberOfBoxes('')
                    props.setCakeShape('')
                    props.setCakeSize('')
                    props.setTopCakeLayerSize('')
                    props.setCakeLayers(CAKE_LAYERS[1])
                    props.setCakeType(e.target.value)
                    props.setCakeFillingOrFrosting(CAKE_FILLING_FROSTING.FONDANT)
                    props.setCakeFlavour(CAKE_FLAVOUR.VANILLA)
                    props.setJamFilling('')
                    props.setLemonCurdFilling('')
                  }}
                />
                <img
                  src={NoveltyCakeIcon}
                  alt="Novelty Cake Icon"
                />
                <span>Novelty / Themed Cake</span>
                <small>(e.g. T-Shirt Cake)</small>
              </label>
              <label
                className={createClass("icon-label", { "icon-label-selected": props.cakeType === CAKE_TYPE.CAKE })}
                htmlFor="cake"
              >
                <input
                  className="icon-radio"
                  type="radio"
                  id="cake"
                  name="cake-type"
                  value={CAKE_TYPE.CAKE}
                  checked={props.cakeType === CAKE_TYPE.CAKE}
                  onChange={(e) => {
                    props.setNumberOfBoxes('')
                    props.setCakeShape(CAKE_SHAPE.ROUND)
                    props.setCakeSize(CAKE_SIZE[10])
                    props.setTopCakeLayerSize('')
                    props.setCakeLayers(CAKE_LAYERS[1])
                    props.setCakeType(e.target.value)
                    props.setJamFilling(CAKE_OPTIONAL.NO)
                    props.setLemonCurdFilling(CAKE_OPTIONAL.NO)
                  }}
                />
                <CakeIcon />
                <span>Single Layer Cake</span>
              </label>
              <label
                className={createClass("icon-label", { "icon-label-selected": props.cakeType === CAKE_TYPE.TWO_TIER_CAKE })}
                htmlFor="two-tier-cake"
              >
                <input
                  className="icon-radio"
                  type="radio"
                  id="two-tier-cake"
                  name="cake-type"
                  value={CAKE_TYPE.TWO_TIER_CAKE}
                  checked={props.cakeType === CAKE_TYPE.TWO_TIER_CAKE}
                  onChange={(e) => {
                    props.setNumberOfBoxes('')
                    props.setCakeShape(CAKE_SHAPE.ROUND)
                    props.setCakeSize(CAKE_SIZE[10])
                    props.setTopCakeLayerSize(CAKE_SIZE[8])
                    props.setCakeLayers(CAKE_LAYERS[2])
                    props.setCakeType(e.target.value)
                    props.setJamFilling(CAKE_OPTIONAL.NO)
                    props.setLemonCurdFilling(CAKE_OPTIONAL.NO)
                  }}
                />
                <TwoTierCakeIcon />
                <span>Two Tier Cake</span>
              </label>
              <label
                className={createClass("icon-label", { "icon-label-selected": props.cakeType === CAKE_TYPE.CUPCAKE })}
                htmlFor="cupcake">
                <input
                  className="icon-radio"
                  type="radio"
                  id="cupcake"
                  name="cake-type"
                  value={CAKE_TYPE.CUPCAKE}
                  checked={props.cakeType === CAKE_TYPE.CUPCAKE}
                  onChange={(e) => {
                    props.setNumberOfBoxes('1')
                    props.setCakeShape('')
                    props.setCakeSize('')
                    props.setTopCakeLayerSize('')
                    props.setCakeLayers('')
                    props.setCakeType(e.target.value)
                    props.setJamFilling('')
                    props.setLemonCurdFilling('')
                  }}
                />
                <CupcakeIcon />
                <span>Cupcakes (box of 12)</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      {props.cakeType === CAKE_TYPE.CUPCAKE &&
        <div className="form-row">
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="cupcake-count">Number of boxes</label>
              <input className="form-control" type="number" min={1} id="cupcake-count" name="number-of-boxes" value={props.numberOfBoxes} onChange={(e) => props.setNumberOfBoxes(e.target.value)} />
            </div>
          </div>
        </div>
      }
      {[CAKE_TYPE.CAKE, CAKE_TYPE.TWO_TIER_CAKE].includes(props.cakeType) &&
        <>
          <div className="form-row">
            <div className="col-sm">
              <div className="form-group">
                <label htmlFor="botton-layer-cake-size">{props.cakeType === CAKE_TYPE.CAKE ? 'Cake Size' : 'Bottom Layer Size'} <span className="star">*</span></label>
                <select
                  className="form-control"
                  id="bottom-layer-cake-size"
                  value={props.cakeSize}
                  onChange={(e) => {
                    if (props.cakeType === CAKE_TYPE.TWO_TIER_CAKE && e.target.value === CAKE_SIZE[8]) {
                      props.setTopCakeLayerSize(CAKE_SIZE[6])
                    }
                    props.setCakeSize(e.target.value)
                  }
                  }
                  name="bottom-layer-cake-size"
                  required
                >
                  <option>{CAKE_SIZE[10]}</option>
                  <option>{CAKE_SIZE[8]}</option>
                  {props.cakeType === CAKE_TYPE.CAKE && <option>{CAKE_SIZE[6]}</option>}
                </select>
              </div>
            </div>
          </div>
        </>
      }
      {props.cakeType === CAKE_TYPE.TWO_TIER_CAKE &&
        <div className="form-row">
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="top-layer-cake-size">Top Layer Size<span className="star">*</span></label>
              <select
                className="form-control"
                id="top-layer-cake-size"
                value={props.topCakeLayerSize}
                onChange={(e) => props.setTopCakeLayerSize(e.target.value)}
                name="top-layer-cake-size"
                required
              >
                {props.cakeSize === CAKE_SIZE[10] && <option>{CAKE_SIZE[8]}</option>}
                <option>{CAKE_SIZE[6]}</option>
              </select>
            </div>
          </div>
        </div>
      }
      {[CAKE_TYPE.CAKE, CAKE_TYPE.TWO_TIER_CAKE].includes(props.cakeType) &&
        <div className="form-row">
          <div className="col-sm">
            <div className="form-group">
              <label htmlFor="cake-shape">Cake Shape <span className="star">*</span></label>
              <select
                className="form-control"
                id="cake-shape"
                value={props.cakeShape}
                onChange={(e) => props.setCakeShape(e.target.value)}
                name="cake-shape"
                required
              >
                <option>{CAKE_SHAPE.ROUND}</option>
                <option>{CAKE_SHAPE.SQAURE}</option>
              </select>
            </div>
          </div>
        </div>
      }

      <div className="form-row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="flavour">Cake Flavour <span className="star">*</span></label>
            <select
              className="form-control"
              id="flavour"
              value={props.cakeFlavour}
              onChange={(e) => props.setCakeFlavour(e.target.value)}
              name="flavour"
              required
            >
              <option>{CAKE_FLAVOUR.VANILLA}</option>
              {props.cakeType !== CAKE_TYPE.NOVELTY && (
                <>
                  <option>{CAKE_FLAVOUR.CARROT}</option>
                  <option>{CAKE_FLAVOUR.LEMON}</option>
                  <option>{CAKE_FLAVOUR.CHOCOLATE}</option>
                  <option>{CAKE_FLAVOUR.RED_VELVET}</option>
                  <option value="Other">Other - explain below</option>
                </>
              )}
            </select>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="filling">Fillings and Frosting <span className="star">*</span></label>
            <select
              className="form-control"
              id="filling"
              value={props.cakeFillingOrFrosting}
              onChange={(e) => {
                if (e.target.value !== CAKE_FILLING_FROSTING.VANILLA_BUTTERCREAM) {
                  props.setJamFilling(CAKE_OPTIONAL.NO)
                }
                if (e.target.value !== CAKE_FILLING_FROSTING.LEMON_BUTTERCREAM) {
                  props.setLemonCurdFilling(CAKE_OPTIONAL.NO)
                }
                props.setCakeFillingOrFrosting(e.target.value)
              }}
              name="filling"
              required
            >
              <option>{CAKE_FILLING_FROSTING.FONDANT}</option>
              {props.cakeType !== CAKE_TYPE.NOVELTY && (
                <>
                  <option>{CAKE_FILLING_FROSTING.VANILLA_BUTTERCREAM}</option>
                  <option>{CAKE_FILLING_FROSTING.LEMON_BUTTERCREAM}</option>
                  <option>{CAKE_FILLING_FROSTING.CHOCOLATE_BUTTERCREAM}</option>
                  <option>{CAKE_FILLING_FROSTING.CREAM_CHEESE}</option>
                  <option value="Other">Other - explain below</option>
                </>
              )}
            </select>
          </div>
        </div>
      </div>
      {props.cakeFillingOrFrosting === CAKE_FILLING_FROSTING.VANILLA_BUTTERCREAM && <div className="form-row">
        <div className="col-sm">
          <div className="form-group">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="jam-filling"
                checked={props.jamFilling === CAKE_OPTIONAL.YES}
                onChange={() => props.setJamFilling(props.jamFilling === CAKE_OPTIONAL.YES ? CAKE_OPTIONAL.NO : CAKE_OPTIONAL.YES)}
                name="jam-filling"
              />
              <label className="form-check-label" htmlFor="jam-filling">Add Jam Layer</label>
            </div>
          </div>
        </div>
      </div>}
      {props.cakeFillingOrFrosting === CAKE_FILLING_FROSTING.LEMON_BUTTERCREAM &&
        <div className="form-row">
          <div className="col-sm">
            <div className="form-group">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="lemon-curd-filling"
                  checked={props.lemonCurdFilling === CAKE_OPTIONAL.YES}
                  onChange={() => props.setLemonCurdFilling(props.lemonCurdFilling === CAKE_OPTIONAL.YES ? CAKE_OPTIONAL.NO : CAKE_OPTIONAL.YES)}
                  name="lemon-curd-filling"
                />
                <label className="form-check-label" htmlFor="lemon-curd-filling">Add Lemon Curd Layer</label>

              </div>
            </div>
          </div>
        </div>
      }
      <div className="form-row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="details">
              Details (description of what you would like on the {props.cakeType.toLowerCase()} e.g. colours,
              theme etc.)
            </label>
            <textarea
              type="text"
              className="form-control"
              id="details"
              placeholder={`Enter details about your ${props.cakeType.toLowerCase()} requirements`}
              value={props.details}
              onChange={(e) => props.setDetails(e.target.value)}
              name="details"
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm">
          <div className={createClass("form-group", "form-image-upload")}>
            <div>
              <label htmlFor="cake-image">
                Upload an image of a cake to help describe how you want it to look like
              </label>
              <input
                ref={fileInput}
                type="file"
                accept="image/*"
                className="form-control"
                id="cake-image"
                onChange={(e) => {
                  if (!e.target.files || e.target.files.length === 0) {
                    props.setCakeImage(undefined)
                  }

                  const file = e.target.files[0];
                  props.setCakeImage(file)
                }}
                name="cake-image"
              />
            </div>
            <div className="img-col">
              {preview && (
                <>
                  <img
                    src={preview}
                    alt="User file upload preview"
                  />
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    onClick={() => {
                      props.setCakeImage(undefined)
                      setPreview(undefined)
                      if ('value' in fileInput.current) {
                        fileInput.current.value = ''
                      }
                    }}
                  >
                    Remove
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="form-row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="delivery-or-collection-time">Delivery / Collection Date and Time <span className="star">*</span></label>
            <input
              type="datetime-local"
              className="form-control"
              id="delivery-or-collection-time"
              value={props.dueDate}
              onChange={(e) => props.setDueDate(e.target.value)}
              min={getCurrentDatetimeLocal()}
              name="delivery-or-collection-time"
              required
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="event-type">Event Type</label>
            <input
              type="text"
              className="form-control"
              id="event-type"
              placeholder="Enter event type e.g. Birthday Party"
              value={props.eventType}
              onChange={(e) => props.setEventType(e.target.value)}
              name="event-type"
            />
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

CakeDetails.propTypes = {
  dueDate: PropTypes.string,
  setDueDate: PropTypes.func.isRequired,
  eventType: PropTypes.string,
  setEventType: PropTypes.func.isRequired,
  cakeFlavour: PropTypes.string,
  setCakeFlavour: PropTypes.func.isRequired,
  cakeFillingOrFrosting: PropTypes.string,
  setCakeFillingOrFrosting: PropTypes.func.isRequired,
  details: PropTypes.string,
  setDetails: PropTypes.func.isRequired,
  cakeImage: PropTypes.any,
  setCakeImage: PropTypes.func.isRequired,
  cakeType: PropTypes.string,
  setCakeType: PropTypes.func.isRequired,
  numberOfBoxes: PropTypes.string,
  setNumberOfBoxes: PropTypes.func.isRequired,
  cakeSize: PropTypes.string,
  setCakeSize: PropTypes.func.isRequired,
  topCakeLayerSize: PropTypes.string,
  setTopCakeLayerSize: PropTypes.func.isRequired,
  cakeShape: PropTypes.string,
  setCakeShape: PropTypes.func.isRequired,
  cakeLayers: PropTypes.string,
  setCakeLayers: PropTypes.func.isRequired,
  jamFilling: PropTypes.string,
  setJamFilling: PropTypes.func.isRequired,
  lemonCurdFilling: PropTypes.string,
  setLemonCurdFilling: PropTypes.func.isRequired,
}

export default CakeDetails;
